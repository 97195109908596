import React, { useEffect, useState } from 'react';
import { Table, Spinner, Container } from 'react-bootstrap';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { ethers } from 'ethers';
import ReactPaginate from 'react-paginate';
import Account from './Account';
import { withdrawalEndoint, WithdrawalItem } from '@eth-optimism/indexer-api';
import axios from 'axios';

import { publicClientL1, publicClientL2 } from '../../config/clients';
import { isAddressEqual } from 'viem';

const optimismSDK = require('@eth-optimism/sdk');

/**
 *
 * @param {`0x${string}`} transactionHash
 * @returns
 */
const getWithdrawalMessageStatus = async (transactionHash) => {
  const receipt = await publicClientL2.getTransactionReceipt({
    hash: transactionHash
  });

  const status = await publicClientL1.getWithdrawalStatus({
    receipt,
    targetChain: publicClientL2.chain
  });

  const message = {};

  if (status === 'finalized') {
    message['message'] = 'Completed';
    message['messageStatus'] = 6;
  } else if (status === 'ready-to-prove') {
    message['message'] = 'Ready to Prove';
    message['messageStatus'] = 3;
  } else if (status === 'ready-to-finalize') {
    message['message'] = 'Claim Withdrawal';
    message['messageStatus'] = 5;
  } else if (status === 'waiting-to-finalize') {
    message['message'] = 'Waiting for Confirmation';
    message['messageStatus'] = 2;
  } else if (status === 'waiting-to-prove') {
    message['message'] = 'In challenge Period';
    message['messageStatus'] = 4;
  }

  return message;
};

const WithdrawAccount = () => {
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [loader, setLoader] = useState();
  const { address, isConnected } = useAccount();
  const [withdrawDetails, setWithdrawDetails] = useState([]);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const getCrossChain = async () => {
    const l2Url = String(process.env.REACT_APP_L2_RPC_URL);
    const l1Provider = new ethers.providers.Web3Provider(
      window.ethereum,
      'any'
    );
    const l2Provider = new ethers.providers.JsonRpcProvider(l2Url);
    const l1Signer = l1Provider.getSigner(address);
    const l2Signer = l2Provider.getSigner(address);
    const zeroAddr = '0x'.padEnd(42, '0');
    const l1Contracts = {
      StateCommitmentChain: zeroAddr,
      CanonicalTransactionChain: zeroAddr,
      BondManager: zeroAddr,
      AddressManager: process.env.REACT_APP_LIB_ADDRESSMANAGER,
      L1CrossDomainMessenger:
        process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
      L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
      OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
      L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY
    };
    const bridges = {
      Standard: {
        l1Bridge: l1Contracts.L1StandardBridge,
        l2Bridge: process.env.REACT_APP_L2_BRIDGE,
        Adapter: optimismSDK.StandardBridgeAdapter
      },
      ETH: {
        l1Bridge: l1Contracts.L1StandardBridge,
        l2Bridge: process.env.REACT_APP_L2_BRIDGE,
        Adapter: optimismSDK.ETHBridgeAdapter
      }
    };
    const crossChainMessenger = new optimismSDK.CrossChainMessenger({
      contracts: {
        l1: l1Contracts
      },
      bridges: bridges,
      l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
      l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
      l1SignerOrProvider: l1Signer,
      l2SignerOrProvider: l2Signer,
      bedrock: true
    });

    return crossChainMessenger;
  };

  function timeConverter(timestamp) {
    var a = new Date(timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  const handleProve = async (event, transactionHash) => {
    try {
      const index = event.target.getAttribute('data-value');
      setLoader(index);
      const getCrossChainMessenger = await getCrossChain();
      const response = await getCrossChainMessenger.proveMessage(
        transactionHash
      );
      const logs = await response.wait();
      if (logs.status === 1) {
        await updateWithdrawalMessageStatus(index, transactionHash);
        setLoader(NaN);
      }
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        setLoader(NaN);
      }
    }
  };

  const handleClaim = async (event, transactionHash) => {
    try {
      const index = event.target.getAttribute('data-value');
      setLoader(index);
      const getCrossChainMessenger = await getCrossChain();
      const response = await getCrossChainMessenger.finalizeMessage(
        transactionHash
      );
      const logs = await response.wait();
      if (logs.status === 1) {
        await updateWithdrawalMessageStatus(index, transactionHash);
        setLoader(NaN);
      }
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        setLoader(NaN);
      }
    }
  };

  /**
   *
   * @param {string} index
   * @param {`0x${string}`} transactionHash
   */
  const updateWithdrawalMessageStatus = async (index, transactionHash) => {
    const message = await getWithdrawalMessageStatus(transactionHash);

    const newWithdrawDetails = withdrawDetails.map((v, i) => {
      if (i === Number(index)) {
        return Object.assign({}, v, message);
      } else {
        return v;
      }
    });

    setWithdrawDetails(newWithdrawDetails);
  };

  useEffect(() => {
    void (async () => {
      if (isConnected && address) {
        if (chain.id !== Number(process.env.REACT_APP_L1_CHAIN_ID)) {
          switchNetwork(process.env.REACT_APP_L1_CHAIN_ID);
          return;
        }

        const withdrawalUrl = withdrawalEndoint({
          baseUrl: process.env.REACT_APP_INDEXER_RPC_URL,
          address
        });

        try {
          const response = await axios.get(withdrawalUrl);

          // const cursor = response.data.cursor;
          // const hasNextPage= response.data.hasNextPage;
          /**@type WithdrawalItem[] */
          const item = response.data.items;

          const withdrawalData = item.map((v) => ({
            direction: 1,
            from: v.from,
            to: v.to,
            l1Token: v.l1TokenAddress,
            l2Token: v.l2TokenAddress,
            amount: v.amount,
            timestamp: v.timestamp,
            transactionHash: v.transactionHash
          }));

          for (let i = 0, l = withdrawalData.length; i < l; i++) {
            const message = await getWithdrawalMessageStatus(
              withdrawalData[i].transactionHash
            );

            withdrawalData[i]['message'] = message.message;
            withdrawalData[i]['messageStatus'] = message.messageStatus;
          }

          setWithdrawDetails(withdrawalData);
        } catch (err) {
          console.error('>>>Get Withdrawal Failed');
        } finally {
          setTransactionLoader(true);
        }
      }
    })();
  }, [isConnected, address, chain, switchNetwork]);

  // =============all Collections pagination start===============
  const [currentItemsCollections, setCurrentItemsCollections] = useState([]);
  const [pageCountCollections, setPageCountCollections] = useState(0);
  const [itemOffsetCollections, setItemOffsetCollections] = useState(0);
  const itemsPerPageCollections = 10;

  const tokenList = [
    {
      type: process.env.REACT_APP_L2_DAI,
      tokenSymbol: 'DAI',
      decimalValue: 18
    },
    {
      type: process.env.REACT_APP_L2_USDT,
      tokenSymbol: 'USDT',
      decimalValue: 6
    },
    {
      type: process.env.REACT_APP_L2_USDC,
      tokenSymbol: 'USDC',
      decimalValue: 6
    },
    {
      type: process.env.REACT_APP_L2_wBTC,
      tokenSymbol: 'wBTC',
      decimalValue: 8
    }
  ];

  function retrieveEthValue(amount, givenType) {
    const weiValue = parseInt(ethers.BigNumber.from(amount)._hex, 16);
    const dynamicDecimal =
      tokenList.filter((a) => isAddressEqual(a.type, givenType))[0]
        ?.decimalValue === undefined
        ? 18
        : tokenList.filter((a) => isAddressEqual(a.type, givenType))[0]
            ?.decimalValue;
    return weiValue / Number('1'.padEnd(dynamicDecimal + 1, 0));
  }

  useEffect(() => {
    if (withdrawDetails) {
      const endOffsetCollections =
        itemOffsetCollections + itemsPerPageCollections;
      setCurrentItemsCollections(
        withdrawDetails.slice(itemOffsetCollections, endOffsetCollections)
      );
      setPageCountCollections(
        Math.ceil(withdrawDetails.length / itemsPerPageCollections)
      );
    } else {
    }
  }, [withdrawDetails, itemOffsetCollections, itemsPerPageCollections]);

  const handlePageClickCollections = (event) => {
    const newOffsetCollections =
      (event.selected * itemsPerPageCollections) % withdrawDetails.length;
    setItemOffsetCollections(newOffsetCollections);
  };
  // =============all Collections pagination end===============

  return (
    <>
      <div className="account_wrap">
        <Container>
          <div className="account_inner_wrap">
            <Account />
            <section className="account_withdraw_table">
              {!transactionLoader ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : withdrawDetails?.length <= 0 ? (
                <h4
                  className="text-center text-white"
                  style={{
                    marginTop: '48px'
                  }}>
                  No Transaction Found
                </h4>
              ) : (
                <Table responsive bordered hover className="table">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Transaction</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItemsCollections.map((element, index) => {
                      const {
                        timestamp,
                        message,
                        transactionHash,
                        amount,
                        messageStatus,
                        l2Token
                      } = element;
                      return (
                        <tr key={index}>
                          <td>{timeConverter(timestamp)}</td>
                          <td>Withdraw</td>
                          <td>
                            {retrieveEthValue(amount, l2Token)}&nbsp;
                            {tokenList.filter((a) =>
                              isAddressEqual(a.type, l2Token)
                            )[0]?.tokenSymbol === undefined
                              ? 'ETH'
                              : tokenList.filter((a) =>
                                  isAddressEqual(a.type, l2Token)
                                )[0]?.tokenSymbol}
                          </td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_L2_EXPLORER_URL}/tx/${transactionHash}`}
                              target="_blank"
                              rel="noreferrer">
                              {`${transactionHash.slice(
                                0,
                                8
                              )}...${transactionHash.slice(-8)}`}
                            </a>
                          </td>
                          <td>
                            {message}
                            {messageStatus === 3 ? (
                              index == loader ? (
                                <button
                                  type="button"
                                  className="btn withdraw_inner_btn">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn withdraw_inner_btn"
                                  data-value={index}
                                  onClick={(event) =>
                                    handleProve(event, transactionHash)
                                  }>
                                  Prove
                                </button>
                              )
                            ) : messageStatus === 5 ? (
                              index == loader ? (
                                <button
                                  type="button"
                                  className="btn withdraw_inner_btn">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn withdraw_inner_btn"
                                  data-value={index}
                                  onClick={(event) =>
                                    handleClaim(event, transactionHash)
                                  }>
                                  Claim
                                </button>
                              )
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              {withdrawDetails?.length > 10 ? (
                <div className="pagination_wrap">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >>"
                    onPageChange={handlePageClickCollections}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCountCollections}
                    previousLabel="<< "
                    containerClassName="pagination justify-content-end"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
              ) : (
                ''
              )}
            </section>
          </div>
        </Container>
      </div>
    </>
  );
};

export default WithdrawAccount;
