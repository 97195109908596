import { erc20ABI, usePrepareContractWrite } from 'wagmi';
import { parseUnits } from 'viem';

// type UseApproveContractProps = {
//   contractAddress?: Address;
//   spender: Address;
//   approveAmount: string;
//   decimals: number;
//   bridgeDirection: 'deposit' | 'withdraw';
// };

/**
 *
 * @param {{contractAddress: `0x${string}`,spender: `0x${string}`,approveAmount: string,decimals: number,bridgeDirection: "deposit"|"withdraw"}} param0
 * @returns
 */
export function useApproveContract({
  contractAddress,
  spender,
  approveAmount,
  decimals,
  bridgeDirection
}) {
  const approveAmountBN =
    approveAmount === '' || Number.isNaN(Number(approveAmount))
      ? parseUnits('0', decimals)
      : parseUnits(approveAmount, decimals);
  const { config: depositConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: erc20ABI,
    functionName: 'approve',
    chainId:
      bridgeDirection === 'deposit'
        ? parseInt(process.env.REACT_APP_L1_CHAIN_ID)
        : parseInt(process.env.REACT_APP_L2_CHAIN_ID),
    args: [spender, approveAmountBN],
    cacheTime: 0
  });
  return depositConfig;
}
