const L1StandardBridge = [
  {
    type: 'constructor',
    stateMutability: 'nonpayable',
    inputs: [
      { type: 'address', name: '_messenger', internalType: 'address payable' }
    ]
  },
  {
    type: 'event',
    name: 'ERC20BridgeFinalized',
    inputs: [
      {
        type: 'address',
        name: 'localToken',
        internalType: 'address',
        indexed: true
      },
      {
        type: 'address',
        name: 'remoteToken',
        internalType: 'address',
        indexed: true
      },
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ERC20BridgeInitiated',
    inputs: [
      {
        type: 'address',
        name: 'localToken',
        internalType: 'address',
        indexed: true
      },
      {
        type: 'address',
        name: 'remoteToken',
        internalType: 'address',
        indexed: true
      },
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ERC20DepositInitiated',
    inputs: [
      {
        type: 'address',
        name: 'l1Token',
        internalType: 'address',
        indexed: true
      },
      {
        type: 'address',
        name: 'l2Token',
        internalType: 'address',
        indexed: true
      },
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ERC20WithdrawalFinalized',
    inputs: [
      {
        type: 'address',
        name: 'l1Token',
        internalType: 'address',
        indexed: true
      },
      {
        type: 'address',
        name: 'l2Token',
        internalType: 'address',
        indexed: true
      },
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: false },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ETHBridgeFinalized',
    inputs: [
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: true },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ETHBridgeInitiated',
    inputs: [
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: true },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ETHDepositInitiated',
    inputs: [
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: true },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'ETHWithdrawalFinalized',
    inputs: [
      { type: 'address', name: 'from', internalType: 'address', indexed: true },
      { type: 'address', name: 'to', internalType: 'address', indexed: true },
      {
        type: 'uint256',
        name: 'amount',
        internalType: 'uint256',
        indexed: false
      },
      {
        type: 'bytes',
        name: 'extraData',
        internalType: 'bytes',
        indexed: false
      }
    ],
    anonymous: false
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'bridgeERC20',
    inputs: [
      { type: 'address', name: '_localToken', internalType: 'address' },
      { type: 'address', name: '_remoteToken', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'bridgeERC20To',
    inputs: [
      { type: 'address', name: '_localToken', internalType: 'address' },
      { type: 'address', name: '_remoteToken', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'bridgeETH',
    inputs: [
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'bridgeETHTo',
    inputs: [
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'depositERC20',
    inputs: [
      { type: 'address', name: '_l1Token', internalType: 'address' },
      { type: 'address', name: '_l2Token', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'depositERC20To',
    inputs: [
      { type: 'address', name: '_l1Token', internalType: 'address' },
      { type: 'address', name: '_l2Token', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'depositETH',
    inputs: [
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'depositETHTo',
    inputs: [
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint32', name: '_minGasLimit', internalType: 'uint32' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'deposits',
    inputs: [
      { type: 'address', name: '', internalType: 'address' },
      { type: 'address', name: '', internalType: 'address' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'finalizeBridgeERC20',
    inputs: [
      { type: 'address', name: '_localToken', internalType: 'address' },
      { type: 'address', name: '_remoteToken', internalType: 'address' },
      { type: 'address', name: '_from', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'finalizeBridgeETH',
    inputs: [
      { type: 'address', name: '_from', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'finalizeERC20Withdrawal',
    inputs: [
      { type: 'address', name: '_l1Token', internalType: 'address' },
      { type: 'address', name: '_l2Token', internalType: 'address' },
      { type: 'address', name: '_from', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'payable',
    outputs: [],
    name: 'finalizeETHWithdrawal',
    inputs: [
      { type: 'address', name: '_from', internalType: 'address' },
      { type: 'address', name: '_to', internalType: 'address' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' },
      { type: 'bytes', name: '_extraData', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'address' }],
    name: 'l2TokenBridge',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      {
        type: 'address',
        name: '',
        internalType: 'contract CrossDomainMessenger'
      }
    ],
    name: 'messenger',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'address', name: '', internalType: 'contract StandardBridge' }
    ],
    name: 'otherBridge',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'string', name: '', internalType: 'string' }],
    name: 'version',
    inputs: []
  },
  { type: 'receive', stateMutability: 'payable' }
];

export default L1StandardBridge;