import { parseEther, parseUnits } from 'viem';
import { usePrepareContractWrite } from 'wagmi';

import OptimismPortal from '../contract-abis/OptimismPortal';

// type UsePrepareETHDepositProps = {
//   userAddress?: `0x${string}`;
//   depositAmount: string;
//   isPermittedToBridge: boolean;
// };

/**
 *
 * @param {{userAddress: `0x${string}`,depositAmount: string,isPermittedToBridge: boolean}} param0
 * @returns
 */
export function usePrepareETHDeposit({
  userAddress,
  depositAmount,
  isPermittedToBridge
}) {
  const { config: depositConfig } = usePrepareContractWrite({
    address: isPermittedToBridge
      ? process.env.REACT_APP_OPTIMISM_PORTAL_PROXY
      : undefined,
    abi: OptimismPortal,
    functionName: 'depositTransaction',
    chainId: parseInt(process.env.REACT_APP_L1_CHAIN_ID),
    args: [
      userAddress,
      parseEther(depositAmount === '' ? '0' : depositAmount),
      parseUnits('1', 5),
      false,
      '0x'
    ],
    value: parseEther(depositAmount === '' ? '0' : depositAmount)
  });

  return depositConfig;
}
