import { mainnet, sepolia } from '@wagmi/core/chains';
import { chainConfig } from 'viem2/op-stack';

const sourceId = Number(process.env.REACT_APP_L1_CHAIN_ID); // sepolia 11_155_111, main 1

const isMainNetwork = sourceId === mainnet.id;

export const nanon = {
  id: Number(process.env.REACT_APP_L2_CHAIN_ID),
  name: process.env.REACT_APP_L2_NETWORK_NAME,
  network: 'NANON',
  iconUrl:
    'https://cloudflare-ipfs.com/ipfs/bafybeiduib2fygrwziqpy4yuqr6vvnd6elhahpigbcowcipjjhk6c3qiny',
  iconBackground: '#000000',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: [String(process.env.REACT_APP_L2_RPC_URL)]
    }
  },
  blockExplorers: {
    default: {
      name: 'NANON Explorer',
      url: process.env.REACT_APP_L2_EXPLORER_URL
    }
  },
  contracts: {
    ...chainConfig.contracts,
    l2OutputOracle: {
      [sourceId]: {
        address: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY
      }
    },
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 4286263
    },
    portal: {
      [sourceId]: {
        address: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY
      }
    },
    l1StandardBridge: {
      [sourceId]: {
        address: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE
      }
    }
  },
  testnet: !isMainNetwork,
  sourceId
};

const supportChains = [isMainNetwork ? mainnet : sepolia, nanon];

export default supportChains;
