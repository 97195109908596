import { createPublicClient, http } from 'viem2';
import { publicActionsL1 } from 'viem2/op-stack';
import supportChains from './chains';

export const publicClientL1 = createPublicClient({
  chain: supportChains[0],
  transport: http()
}).extend(publicActionsL1());

export const publicClientL2 = createPublicClient({
  chain: supportChains[1],
  transport: http()
});
