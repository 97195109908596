import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import ReactPaginate from 'react-paginate';
import Account from './Account';
import { depositEndpoint, DepositItem } from '@eth-optimism/indexer-api';
import axios from 'axios';
import { isAddressEqual } from 'viem';

const DepositAccount = () => {
  const [loader, setLoader] = useState(false);
  const tokenList = [
    {
      type: process.env.REACT_APP_L1_DAI,
      tokenSymbol: 'DAI',
      decimalValue: 18
    },
    {
      type: process.env.REACT_APP_L1_USDT,
      tokenSymbol: 'USDT',
      decimalValue: 6
    },
    {
      type: process.env.REACT_APP_L1_USDC,
      tokenSymbol: 'USDC',
      decimalValue: 6
    },
    {
      type: process.env.REACT_APP_L1_wBTC,
      tokenSymbol: 'wBTC',
      decimalValue: 8
    }
  ];

  const { address, isConnected } = useAccount();
  const [depositDetails, setDepositDetails] = useState([]);

  function timeConverter(timestamp) {
    var a = new Date(timestamp * 1000);
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =
      date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  function retrieveEthValue(amount, givenType) {
    const weiValue = parseInt(ethers.BigNumber.from(amount)._hex, 16);
    const dynamicDecimal =
      tokenList.filter((a) => isAddressEqual(a.type, givenType))[0]
        ?.decimalValue === undefined
        ? 18
        : tokenList.filter((a) => isAddressEqual(a.type, givenType))[0]
            ?.decimalValue;
    return weiValue / Number('1'.padEnd(dynamicDecimal + 1, 0));
  }

  // =============all Collections pagination start===============
  const [currentItemsCollections, setCurrentItemsCollections] = useState([]);
  const [pageCountCollections, setPageCountCollections] = useState(0);
  const [itemOffsetCollections, setItemOffsetCollections] = useState(0);
  const itemsPerPageCollections = 10;

  useEffect(() => {
    if (depositDetails) {
      const endOffsetCollections =
        itemOffsetCollections + itemsPerPageCollections;
      setCurrentItemsCollections(
        depositDetails.slice(itemOffsetCollections, endOffsetCollections)
      );
      setPageCountCollections(
        Math.ceil(depositDetails.length / itemsPerPageCollections)
      );
    } else {
    }
  }, [depositDetails, itemOffsetCollections, itemsPerPageCollections]);

  const handlePageClickCollections = (event) => {
    const newOffsetCollections =
      (event.selected * itemsPerPageCollections) % depositDetails.length;
    setItemOffsetCollections(newOffsetCollections);
  };
  // =============all Collections pagination end===============

  useEffect(() => {
    void (async () => {
      if (isConnected && address) {
        const depositUrl = depositEndpoint({
          baseUrl: process.env.REACT_APP_INDEXER_RPC_URL,
          address
        });

        try {
          const response = await axios.get(depositUrl);

          // const cursor = response.data.cursor;
          // const hasNextPage= response.data.hasNextPage;
          /**@type DepositItem[] */
          const item = response.data.items;

          const depositData = item.map((v) => ({
            direction: 0,
            from: v.from,
            to: v.to,
            l1Token: v.l1TokenAddress,
            l2Token: v.l2TokenAddress,
            amount: v.amount,
            timestamp: v.timestamp,
            transactionHash: v.l1TxHash
          }));

          setDepositDetails(depositData);
        } catch (err) {
          console.error('>>>Get Deposit Failed');
        } finally {
          setLoader(true);
        }
      }
    })();
  }, [isConnected, address]);

  return (
    <>
      <div className="account_wrap">
        <Container>
          <div className="account_inner_wrap">
            <Account />
            <section className="account_withdraw_table">
              {!loader ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : depositDetails?.length <= 0 ? (
                <h4
                  className="text-center text-white"
                  style={{
                    marginTop: '48px'
                  }}>
                  No Transaction Found
                </h4>
              ) : (
                <Table responsive bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Transaction</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItemsCollections.map((element, index) => {
                      const { timestamp, transactionHash, amount, l1Token } =
                        element;
                      return (
                        <tr key={index}>
                          <td>{timeConverter(timestamp)}</td>
                          <td>Deposit</td>
                          <td>
                            {retrieveEthValue(amount, l1Token)}&nbsp;
                            {tokenList.filter((a) =>
                              isAddressEqual(a.type, l1Token)
                            )[0]?.tokenSymbol === undefined
                              ? 'ETH'
                              : tokenList.filter((a) =>
                                  isAddressEqual(a.type, l1Token)
                                )[0]?.tokenSymbol}
                          </td>
                          <td>
                            <a
                              href={`https://sepolia.etherscan.io/tx/${transactionHash}`}
                              target="_blank"
                              rel="noreferrer">
                              {`${transactionHash.slice(
                                0,
                                8
                              )}...${transactionHash.slice(-8)}`}
                            </a>
                          </td>
                          <td>Completed</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              {depositDetails?.length > 10 ? (
                <div className="pagination_wrap">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >>"
                    onPageChange={handlePageClickCollections}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={pageCountCollections}
                    previousLabel="<< "
                    containerClassName="pagination justify-content-end"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
              ) : (
                ''
              )}
            </section>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DepositAccount;
