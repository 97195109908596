import { parseUnits } from 'viem';
import { usePrepareContractWrite } from 'wagmi';

import L1StandardBridge from '../contract-abis/L1StandardBridge';

// type UsePrepareERC20DepositProps = {
//   asset: Asset;
//   depositAmount: string;
//   isPermittedToBridge: boolean;
// };

/**
 *
 * @param {{asset: {L1contract: `0x${string}`, L2contract: `0x${string}`, decimals: number},depositAmount: string,isPermittedToBridge: boolean}} param0
 * @returns
 */
export function usePrepareERC20Deposit({
  asset,
  depositAmount,
  isPermittedToBridge
}) {
  const { config: depositConfig } = usePrepareContractWrite({
    address:
      isPermittedToBridge && depositAmount !== ''
        ? process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE
        : undefined,
    abi: L1StandardBridge,
    functionName: 'depositERC20',
    chainId: parseInt(process.env.REACT_APP_L1_CHAIN_ID),
    args: [
      asset.L1contract,
      asset.L2contract,
      depositAmount !== ''
        ? parseUnits(depositAmount, asset.decimals)
        : parseUnits('0', asset.decimals),
      100000,
      '0x'
    ],
    cacheTime: 0,
    staleTime: 1
  });
  return depositConfig;
}
