import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation
} from 'react-router-dom';
import Header from './components/common/Header';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import WithdrawAccount from './components/account/WithdrawAccount';
import DepositAccount from './components/account/DepositAccount';
import React, { useMemo } from 'react';
import sidebarLogo from './assets/images/sidebar-logo.png';
import { HiFire } from 'react-icons/hi';
import { IoPlanetSharp } from 'react-icons/io5';
function App() {
  return (
    <>
      <BrowserRouter>
        <div className="sidebar">
          <div>
            <img src={sidebarLogo} alt="" className="logo" />
            <SidebarButton />
          </div>

          <div className="bottom">
            <a
              href="https://www.alchemy.com/faucets/ethereum-sepolia"
              rel="noreferrer"
              target="_blank">
              <HiFire style={{ fontSize: 24 }} />
              <span>Gas Faucet</span>
            </a>
            <a
              href="https://sepolia-explorer.nanon.network"
              rel="noreferrer"
              target="_blank">
              <IoPlanetSharp style={{ fontSize: 24 }} />
              <span>Try Testnet</span>
            </a>
          </div>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header />
          <div className="main_wrap">
            <Routes>
              <Route path="/" element={<Deposit />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/withdraw" element={<Withdraw />} />
              <Route path="/account/deposit" element={<DepositAccount />} />
              <Route path="/account/withdraw" element={<WithdrawAccount />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

const SidebarButton = () => {
  const location = useLocation();

  const isActivities = useMemo(() => {
    return (
      location.pathname === '/account/deposit' ||
      location.pathname === '/account/withdraw'
    );
  }, [location.pathname]);

  const isBridge = useMemo(() => {
    return (
      location.pathname === '/deposit' || location.pathname === '/withdraw'
    );
  }, [location.pathname]);
  return (
    <ul>
      <li>
        <Link to="/deposit" className={`${isBridge ? 'active' : ''}`}>
          Bridge
        </Link>
      </li>
      <li>
        <Link
          to="/account/deposit"
          className={`${isActivities ? 'active' : ''}`}>
          Activities
        </Link>
      </li>
    </ul>
  );
};

export default App;
