import { erc20ABI, useContractRead } from 'wagmi';

// type UseIsContractApprovedProps = {
//   contactAddress?: `0x${string}`;
//   address?: `0x${string}`;
//   spender: `0x${string}`;
//   bridgeDirection: 'deposit' | 'withdraw';
// };

/**
 *
 * @param {{contactAddress: `0x${string}`,address: `0x${string}`,spender: `0x${string}`,bridgeDirection: "deposit"|"withdraw"}} param0
 * @returns
 */
export function useIsContractApproved({
  contactAddress,
  address,
  spender,
  bridgeDirection
}) {
  return useContractRead({
    address: contactAddress,
    abi: erc20ABI,
    functionName: 'allowance',
    watch: true,
    chainId:
      bridgeDirection === 'deposit'
        ? parseInt(process.env.REACT_APP_L1_CHAIN_ID)
        : parseInt(process.env.REACT_APP_L2_CHAIN_ID),
    args: [address, spender]
  });
}
