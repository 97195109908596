import '../assets/style/deposit.scss';

import React, { useState, useMemo, useEffect } from 'react';
import { Form, Spinner, Image } from 'react-bootstrap';
import {
  useAccount,
  useConnect,
  useNetwork,
  useSwitchNetwork,
  useBalance,
  useContractWrite,
  usePublicClient
} from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { parseUnits } from 'viem';

import { Dai, Usdt, Usdc, Ethereum, Btc } from 'react-web3-icons';
import { IoMdWallet } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import { HiSwitchHorizontal } from 'react-icons/hi';
import toIcn from '../assets/images/logo.png';
import metamask from '../assets/images/metamask.svg';

import TabMenu from './TabMenu';
import { usePrepareETHDeposit } from '../hooks/usePrepareETHDeposit';
import { usePrepareERC20Deposit } from '../hooks/usePrepareERC20Deposit';
import { useApproveContract } from '../hooks/useApproveContract';
import { useIsContractApproved } from '../hooks/useIsContractApproved';
import { sleep } from '../utils/common';

const Deposit = () => {
  const [ethValue, setEthValue] = useState('');
  const [sendToken, setSendToken] = useState('ETH');
  const [errorInput, setErrorInput] = useState('');
  const [loader, setLoader] = useState(false);
  const [checkMetaMask, setCheckMetaMask] = useState(false);
  const [checkDisabled, setCheckDisabled] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({
    L1contract: process.env.REACT_APP_L1_USDT,
    L2contract: process.env.REACT_APP_L2_USDT,
    decimals: 6
  });

  const { address, isConnected } = useAccount();
  const { chain, chains } = useNetwork();

  const publicClient = usePublicClient({
    chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  });

  const { connect } = useConnect({
    connector: new InjectedConnector({ chains }),
    onError(error) {
      console.log('Error', error);
    },
    onMutate(args) {
      console.log('Mutate', args);
      if (args.connector.ready === true) {
        setCheckMetaMask(false);
      } else {
        setCheckMetaMask(true);
      }
    },
    onSettled(data, error) {
      console.log('Settled', { data, error });
    },
    onSuccess(data) {
      console.log('Success', data);
    }
  });

  const { switchNetwork } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log('Error', error);
    },
    onMutate(args) {
      console.log('Mutate', args);
    },
    onSettled(data, error) {
      console.log('Settled', { data, error });
    },
    onSuccess(data) {
      console.log('Success', data);
    }
  });

  const { data } = useBalance({
    address: address,
    watch: true,
    chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  });

  const dataUSDT = useBalance({
    address: address,
    token: process.env.REACT_APP_L1_USDT,
    watch: true,
    chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  });

  // const dataDAI = useBalance({
  //   address: address,
  //   token: process.env.REACT_APP_L1_DAI,
  //   watch: true,
  //   chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  // });

  // const dataUSDC = useBalance({
  //   address: address,
  //   token: process.env.REACT_APP_L1_USDC,
  //   watch: true,
  //   chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  // });

  // const datawBTC = useBalance({
  //   address: address,
  //   token: process.env.REACT_APP_L1_wBTC,
  //   watch: true,
  //   chainId: Number(process.env.REACT_APP_L1_CHAIN_ID)
  // });

  const handleSwitch = () => {
    switchNetwork(process.env.REACT_APP_L1_CHAIN_ID);
  };

  // deposit eth
  const depositETHConfig = usePrepareETHDeposit({
    userAddress: address,
    depositAmount: ethValue,
    isPermittedToBridge: !checkDisabled
  });
  const { writeAsync: depositETHWrite } = useContractWrite(depositETHConfig);

  // approve erc20
  const approveConfig = useApproveContract({
    contractAddress: selectedAsset.L1contract,
    spender: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
    approveAmount: ethValue,
    decimals: selectedAsset.decimals,
    bridgeDirection: 'deposit'
  });
  const { writeAsync: approveWrite } = useContractWrite(approveConfig);

  // deposit erc20
  const depositERC20Config = usePrepareERC20Deposit({
    asset: selectedAsset,
    depositAmount: ethValue,
    isPermittedToBridge: !checkDisabled
  });
  const { writeAsync: depositERC20Write } =
    useContractWrite(depositERC20Config);

  // read erc20 Approval
  const { data: readERC20Approval, error: readERC20ApprovalError } =
    useIsContractApproved({
      contactAddress: selectedAsset.L1contract,
      address,
      spender: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
      bridgeDirection: 'deposit'
    });

  const readApprovalResult = useMemo(() => {
    const depositAmountBN =
      ethValue === '' || Number.isNaN(Number(ethValue))
        ? parseUnits('0', selectedAsset.decimals)
        : parseUnits(ethValue, selectedAsset.decimals);
    return !!(
      !readERC20ApprovalError &&
      readERC20Approval &&
      readERC20Approval >= depositAmountBN
    );
  }, [
    ethValue,
    selectedAsset.decimals,
    readERC20ApprovalError,
    readERC20Approval
  ]);

  const isReadyDepositContract = useMemo(
    () => (sendToken === 'ETH' ? !!depositETHWrite : !!depositERC20Write),
    [sendToken, depositETHWrite, depositERC20Write]
  );

  useEffect(() => {
    console.log('isReadyDepositContract>>>', isReadyDepositContract);
    console.log('depositERC20Write>>>', depositERC20Write);
  }, [isReadyDepositContract, depositERC20Write]);

  const handleDeposit = async () => {
    try {
      if (!ethValue) {
        setErrorInput('Please enter the amount');
      } else {
        if (!parseFloat(ethValue) > 0) {
          setErrorInput('Invalid Amount Entered!');
        } else {
          setLoader(true);

          let depositResult;
          if (sendToken === 'ETH') {
            depositResult = await depositETHWrite?.();
          } else {
            depositResult = await depositERC20Write?.();
          }

          await sleep();

          if (depositResult?.hash) {
            // const depositTxHash = depositResult.hash;
            const depositTransaction =
              await publicClient.waitForTransactionReceipt({
                hash: depositResult.hash
              });
            console.log('depositTransaction>>>', depositTransaction);
          }

          setLoader(false);
          setEthValue('');
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const initiateApproval = () => {
    void (async () => {
      setLoader(true);
      const approveResult = await approveWrite?.();
      await sleep();
      if (approveResult?.hash) {
        // wait for confirmations
        const approveTransaction = await publicClient.waitForTransactionReceipt(
          {
            hash: approveResult.hash
          }
        );
        console.log('approveTransaction>>>', approveTransaction);
      }
      setLoader(false);
    })();
  };

  const handleChange = (e) => {
    if (sendToken === 'ETH') {
      if (Number(data?.formatted) < e.target.value) {
        setErrorInput('Insufficient ETH balance.');
        setCheckDisabled(true);
      } else {
        setCheckDisabled(false);
        setErrorInput('');
      }
      setEthValue(e.target.value);
    }

    if (sendToken === 'USDT') {
      if (Number(dataUSDT.data?.formatted) < e.target.value) {
        setErrorInput('Insufficient USDT balance.');
        setCheckDisabled(true);
      } else {
        setCheckDisabled(false);
        setErrorInput('');
      }
      setEthValue(e.target.value);
    }

    // if (sendToken === 'DAI') {
    //   if (Number(dataDAI.data?.formatted) < e.target.value) {
    //     setErrorInput('Insufficient DAI balance.');
    //     setCheckDisabled(true);
    //   } else {
    //     setCheckDisabled(false);
    //     setErrorInput('');
    //   }
    //   setEthValue(e.target.value);
    // }

    // if (sendToken === 'wBTC') {
    //   if (Number(datawBTC.data?.formatted) < e.target.value) {
    //     setErrorInput('Insufficient wBTC balance.');
    //     setCheckDisabled(true);
    //   } else {
    //     setCheckDisabled(false);
    //     setErrorInput('');
    //   }
    //   setEthValue(e.target.value);
    // }

    // if (sendToken === 'USDC') {
    //   if (Number(dataUSDC.data?.formatted) < e.target.value) {
    //     setErrorInput('Insufficient USDC balance.');
    //     setCheckDisabled(true);
    //   } else {
    //     setErrorInput('');
    //     setCheckDisabled(false);
    //   }
    //   setEthValue(e.target.value);
    // }
  };

  return (
    <>
      <div className="bridge_wrap">
        <TabMenu />
        <section className="deposit_wrap">
          <div className="deposit_price_wrap">
            <div className="deposit_price_title">
              <p>From</p>
              <h5>
                <FaEthereum /> {process.env.REACT_APP_L1_CHAIN_NAME}
              </h5>
            </div>
            <div className="deposit_input_wrap">
              <Form>
                <div className="deposit_inner_input">
                  <Form.Control
                    type="number"
                    value={ethValue}
                    onChange={handleChange}
                    placeholder="0"
                    min="0"
                    step="any"
                  />
                  <Form.Select
                    aria-label="Default select example"
                    className="select_wrap"
                    onChange={({ target }) => {
                      setSendToken(target.value);
                      setEthValue('');
                      if (target.value === 'USDT') {
                        setSelectedAsset({
                          L1contract: process.env.REACT_APP_L1_USDT,
                          L2contract: process.env.REACT_APP_L2_USDT,
                          decimals: 6
                        });
                      }
                    }}>
                    <option>ETH</option>
                    {/* <option value="DAI">DAI</option> */}
                    {/* <option value="USDC">USDC</option> */}
                    {/* <option value="USDT">USDT</option> */}
                    {/* <option value="wBTC">wBTC</option> */}
                  </Form.Select>
                </div>
                <div className="input_icn_wrap">
                  {sendToken === 'ETH' ? (
                    <span className="input_icn">
                      <Ethereum style={{ fontSize: '1.5rem' }} />
                    </span>
                  ) : sendToken === 'DAI' ? (
                    <span className="input_icn">
                      <Dai style={{ fontSize: '1.5rem' }} />
                    </span>
                  ) : sendToken === 'USDT' ? (
                    <span className="input_icn">
                      <Usdt style={{ fontSize: '1.5rem' }} />
                    </span>
                  ) : sendToken === 'wBTC' ? (
                    <span className="input_icn">
                      <Btc style={{ fontSize: '1.5rem' }} />
                    </span>
                  ) : (
                    <span className="input_icn">
                      <Usdc style={{ fontSize: '1.5rem' }} />
                    </span>
                  )}
                </div>
              </Form>
            </div>
            {errorInput && <small className="text-danger">{errorInput}</small>}
            {sendToken === 'ETH'
              ? address && (
                  <p className="wallet_bal mt-2">
                    Balance: {Number(data?.formatted).toFixed(5)} ETH
                  </p>
                )
              : sendToken === 'USDT'
              ? address && (
                  <p className="wallet_bal mt-2">
                    Balance: {Number(dataUSDT.data?.formatted).toFixed(5)} USDT
                  </p>
                )
              : sendToken === 'DAI'
              ? address && (
                  <p className="wallet_bal mt-2">
                    {/* Balance: {Number(dataDAI.data?.formatted).toFixed(5)} DAI */}
                  </p>
                )
              : sendToken === 'wBTC'
              ? address && (
                  <p className="wallet_bal mt-2">
                    {/* Balance: {Number(datawBTC.data?.formatted).toFixed(5)} wBTC */}
                  </p>
                )
              : address && (
                  <p className="wallet_bal mt-2">
                    {/* Balance: {Number(dataUSDC.data?.formatted).toFixed(5)} USDC */}
                  </p>
                )}
          </div>
          <div className="deposit_details_wrap">
            <div className="deposit_details">
              <p>To</p>
              <h5>
                <Image src={toIcn} alt="To icn" fluid />
                {process.env.REACT_APP_L2_CHAIN_NAME}
              </h5>
            </div>
            <div className="deposit_inner_details">
              {sendToken === 'ETH' ? (
                <span className="input_icn">
                  <Ethereum style={{ fontSize: '1.5rem' }} />
                </span>
              ) : sendToken === 'DAI' ? (
                <span className="input_icn">
                  <Dai style={{ fontSize: '1.5rem' }} />
                </span>
              ) : sendToken === 'USDT' ? (
                <span className="input_icn">
                  <Usdt style={{ fontSize: '1.5rem' }} />
                </span>
              ) : sendToken === 'wBTC' ? (
                <span className="input_icn">
                  <Btc style={{ fontSize: '1.5rem' }} />
                </span>
              ) : (
                <span className="input_icn">
                  <Usdc style={{ fontSize: '1.5rem' }} />
                </span>
              )}
              <p>
                You’ll receive: {ethValue ? ethValue : '0'} {sendToken}
              </p>
            </div>
          </div>
          <div className="deposit_btn_wrap">
            {checkMetaMask ? (
              <a
                className="btn deposit_btn"
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer">
                <Image src={metamask} alt="metamask icn" fluid /> Please Install
                Metamask Wallet
              </a>
            ) : !isConnected ? (
              <button className="btn deposit_btn" onClick={() => connect()}>
                <IoMdWallet />
                Connect Wallet
              </button>
            ) : chain.id !== Number(process.env.REACT_APP_L1_CHAIN_ID) ? (
              <button className="btn deposit_btn" onClick={handleSwitch}>
                <HiSwitchHorizontal />
                Switch to {process.env.REACT_APP_L1_CHAIN_NAME}
              </button>
            ) : checkDisabled ? (
              <button className="btn deposit_btn" disabled={true}>
                Deposit
              </button>
            ) : readApprovalResult || sendToken === 'ETH' ? (
              <button
                className="btn deposit_btn"
                onClick={handleDeposit}
                disabled={loader || !isReadyDepositContract}>
                {loader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  'Deposit'
                )}
              </button>
            ) : (
              <button
                className="btn deposit_btn"
                onClick={initiateApproval}
                disabled={loader || !approveWrite}>
                {loader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  'Approval'
                )}
              </button>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Deposit;
